import "./App.css";
import { app, auth } from "./helpers/firebase";
import { useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getRoutes } from "./routes";

function App() {
  const [user, setUser] = useState(
    localStorage.getItem("turUser") &&
      JSON.parse(localStorage.getItem("turUser"))
  );

  const routing = useRoutes(getRoutes(user));

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  return <>{routing}</>;
}

export default App;
