import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../helpers/firebase";

const RollingForm = ({ setContentModal }) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [school, setSchool] = useState("");
  const [schoolErr, setSchoolErr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");

  const isValidRolling = (e) => {
    let isValid = false;
    if (!name) {
      setNameErr("Name is required!");
    } else if (!school) {
      setSchoolErr("School is required!");
    } else if (!description) {
      setDescriptionErr("Discussion is required!");
    } else if (description.length > 250) {
      setDescriptionErr("Maximum 250 characters allowed for the Description!");
    } else {
      isValid = true;
    }
    return isValid;
  };

  const onCloseHandler = () => {
    setContentModal(false);
    setName("");
    setSchool("");
    setDescription("");
    setNameErr("");
    setSchoolErr("");
    setDescriptionErr("");
  };

  const addTodo = async (e) => {
    e.preventDefault();

    if (isValidRolling()) {
      const data = {
        name,
        school,
        description:
          description.includes("http") || description.includes("www") || description.includes(".com")
            ? description
                .split(" ")
                ?.map((item) => {
                  if (item.includes("http")) {
                    return `<a href=${item} target=_blank>${item}</a>`;
                  } else if (item.includes("www")) {
                    return `<a href=https://${item} target=_blank>${item}</a>`;
                  } else if (item.includes(".com")) {
                    if (item.includes("http"))
                      return `<a href=${item} target=_blank>${item}</a>`;
                    else
                      return `<a href=https://${item} target=_blank>${item}</a>`;  
                  } else {
                    return item;
                  }
                })
                ?.join(" ")
            : description,
        date: new Date(),
      };

      console.log("abcd", data);

      try {
        const docRef = await addDoc(collection(db, "todos"), {
          todo: data,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      setName("");
      setSchool("");
      setDescription("");
      setContentModal(false);
    }
  };

  return (
    <div className="t3-content-modal-content">
      <div className="t3-content-modal-body">
        <div className="t3-content-modal-header">
          <h2>Add to the Discussion</h2>
          <button
            onClick={onCloseHandler}
            className="t3-content-modal-close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="t3-content-modal-body-content">
          <div className="t3-auth-input-content">
            <div className="t3-auth-input-inner-content">
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Your name"
                value={name}
                onChange={(e) => {
                  setNameErr("");
                  setName(e.target.value);
                }}
                className="t3-auth-input"
              />
              <label htmlFor="name" className="t3-auth-input-content-label">
                Your Name
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="t3-auth-input-content-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            {nameErr && (
              <span className="t3-auth-input-content-error">{nameErr}</span>
            )}
          </div>
          <div className="t3-auth-input-content">
            <div className="t3-auth-input-inner-content">
              <input
                id="schoolName"
                type="text"
                name="school"
                placeholder="Name of School"
                value={school}
                onChange={(e) => {
                  setSchoolErr("");
                  setSchool(e.target.value);
                }}
                className="t3-auth-input"
              />
              <label
                htmlFor="schoolName"
                className="t3-auth-input-content-label"
              >
                Name of School
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="t3-auth-input-content-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                />
              </svg>
            </div>
            {schoolErr && (
              <span className="t3-auth-input-content-error">{schoolErr}</span>
            )}
          </div>
          <div className="t3-auth-input-content">
            <div className="t3-auth-input-inner-content">
              <textarea
                id="description"
                type="text"
                name="description"
                placeholder="What would you like to contribute to this rolling discussion?"
                value={description}
                onChange={(e) => {
                  setDescriptionErr("");
                  setDescription(e.target.value);
                }}
                className="t3-auth-input"
              />
              <label
                htmlFor="description"
                className="t3-auth-input-content-label"
              >
                Your Thoughts
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="t3-auth-input-content-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
            </div>
            {descriptionErr && (
              <span className="t3-auth-input-content-error">
                {descriptionErr}
              </span>
            )}
          </div>
          <button type="submit" className="t3-theme-btn" onClick={addTodo}>
            Post Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default RollingForm;
