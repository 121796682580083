import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../helpers/firebase";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

import logo from "../../assets/image/Logo.svg";
import loginBannerImage from "../../assets/image/LoginBannerImage.png";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const isValidAuth = (e) => {
    let isValid = false;
    if (!email) {
      setEmailErr("Email is required!");
    } else if (!password) {
      setPasswordErr("Password is required!");
    } else {
      isValid = true;
    }
    return isValid;
  };

  const submitHandler = () => {
    if (isValidAuth()) {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          toast.success("Welcome Back!", 2000);
          localStorage.setItem("turUser", JSON.stringify(userCredential.user));
        })
        .catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage, 2000);
        });
    }
  };

  return (
    <>
      <div className="t3-login-section">
        <div className="t3-login-banner-content">
          <img src={loginBannerImage} />
        </div>
        <div className="t3-login-form-content">
          <Link to={'/login'} className="t3-auth-logo">
            <img src={logo} alt="logo" draggable="false" />
          </Link>
          <div className="t3-login-form">
            <h2 className="t3-auth-form-heading">👋🏻 Hello Again!</h2>
            <p className="t3-auth-form-paragraph">For your protection, please verify your identity.</p>
            <div className="t3-auth-input-content">
              <div className="t3-auth-input-inner-content">
                <input
                  id="email"
                  type={"email"}
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmailErr("");
                    setEmail(e.target.value);
                  }}
                  className="t3-auth-input"
                />
                <label htmlFor="email" className="t3-auth-input-content-label">Email Address</label>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="t3-auth-input-content-icon">
                  <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                </svg>
              </div>
              {emailErr && <span className="t3-auth-input-content-error">{emailErr}</span>}
            </div>
            <div className="t3-auth-input-content">
              <div className="t3-auth-input-inner-content">
                <input
                  id="password"
                  type={"password"}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPasswordErr("");
                    setPassword(e.target.value);
                  }}
                  className="t3-auth-input"
                />
                <label htmlFor="password" className="t3-auth-input-content-label">Password</label>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="t3-auth-input-content-icon">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </div>
              {passwordErr && <span className="t3-auth-input-content-error">{passwordErr}</span>}
            </div>
            <button onClick={submitHandler} className="t3-theme-btn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
