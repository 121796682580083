import { useEffect, useRef, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../helpers/firebase";
import { signOut } from "firebase/auth";
import { CSVLink } from "react-csv";
import RollingForm from "../../component/RollingForm";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link } from "react-router-dom";
import logo from "../../assets/image/Logo.svg";
import DeleteDataModal from "../../component/DeleteDataModal";
var Scroll = require("react-scroll");
// var Events = Scroll.Events;

const Todo = () => {
  var scrollFr = Scroll.animateScroll;
  const ref = useRef(false);
  const downRef = useRef(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [todos, setTodos] = useState([]);
  const [todosForCsv, setTodosForCsv] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [contentModal, setContentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [docHeight, setDocHeight] = useState(
    document.documentElement.scrollHeight
  );

  const handle = useFullScreenHandle();

  const user =
    localStorage.getItem("turUser") &&
    JSON.parse(localStorage.getItem("turUser"));

  const logoutHandler = () => {
    localStorage.removeItem("turUser");
    signOut(auth);
  };

  const deleteHandler = (id) => {
    setDeleteModal(true);
    setDeleteModalId(id);
  };

  let color = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];
  Math.floor(Math.random() * 11);

  const deleteAllHandler = () => {
    setDeleteModal(true);
  };

  // console.log("scroll", scroll[0]);

  const stopScrollHandler = () => {
    if (
      document.documentElement.scrollHeight -
        document.documentElement.scrollTop -
        document.documentElement.clientHeight ===
      0
    ) {
      scrollFr.scrollToTop({ duration: 0 });
    }
    const docRef = doc(
      db,
      "isScroll",
      process.env.REACT_APP_IS_SCROLLABLE_TABLE_ID
    );

    const data = {
      isScrollEffect: !scroll,
    };

    updateDoc(docRef, data)
      .then((docRef) => {
        // console.log(
        //   "A New Document Field has been added to an existing document"
        // );
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const contentModalHandler = () => {
    setContentModal(true);
  };

  function calculateTime(item) {
    return document.body.scrollHeight * 100;
    // if (window.innerWidth > 1400) {
    //   return item.length * 1500;
    // } else if (window.innerWidth > 1200) {
    //   return item.length * 1700;
    // } else if (window.innerWidth > 1200) {
    //   return item.length * 1900;
    // } else if (window.innerWidth > 1000) {
    //   return item.length * 2100;
    // } else if (window.innerWidth > 800) {
    //   return item.length * 2300;
    // } else if (window.innerWidth > 600) {
    //   return item.length * 2500;
    // } else if (window.innerWidth > 400) {
    //   return item.length * 2700;
    // } else if (window.innerWidth > 300) {
    //   return item.length * 300;
    // }
    // return docHeight * 60;
  }

  const scrollInit = () => {
    const options = {
      // duration: (scroll[0]?.isScrollEffect ? calculateTime(todos): 1000000000),
      duration: (scroll ? calculateTime(todos): 1000000000),

      smooth: "linear",
      //containerId: "scrollElement",
      // delay: 0,
      isDynamic: true,
    };
    console.log('ee', options.duration, scroll);
    // var cl = new Promise((resolve, reject) => calculateTime(todos));
    // options.duration = (scroll[0]?.isScrollEffect ? cl: 1000000000);
    console.log('dd', options.duration);
    scrollFr.scrollToBottom(options);
  };

  const scrollToDownAgain = () => {
    var downSecond = true;

    var myDownInterval = window.setInterval(() => {
      console.log('down interval ', document.documentElement.scrollTop);
      if (document.documentElement.scrollTop <= 2) {
        console.log('scroll up');
        scrollInit();
        // setIsLoaded(true);
        downRef.current = true;
        scrollDetector();
        window.clearInterval(myDownInterval);
      }

      if (downSecond && document.documentElement.scrollTop > 2) {
        downSecond = false;
        scrollFr.scrollToTop({ duration: 0 });
      }
    }, 1000);
  };

  const scrollDetector = () => {
    console.log('detector');
    if (isLoaded || !ref.current || downRef.current) {
      console.log('detector inside');
      var start = true;
      var detectorInterValId = window.setInterval(() => {
        console.log('detector interval ');
        downRef.current = false;
        setIsLoaded(false);

        if (
          start &&
          document.documentElement.scrollHeight -
            document.documentElement.scrollTop -
            document.documentElement.clientHeight ===
            0
        ) {
          console.log('detector interval at 0');
          start = false;
          var myInterval = window.setInterval(() => {
            start = true;
            console.log('detector interval dn');
            scrollToDownAgain();
            window.clearInterval(myInterval);
          }, 10000);
          window.clearInterval(detectorInterValId);
        }
      }, 5000);
    }
  };

  useEffect(() => {
    const getLabels = async () => {
      const colRef = query(collection(db, "todos"));
      onSnapshot(colRef, (snapshot) => {
        setTodos(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    const getScroll = async () => {
      console.log('gotScroll');
      const colRef = query(collection(db, "isScroll"));
      onSnapshot(colRef, (snapshot) => {
        var x = snapshot.docs.map((doc) => ({
          ...doc.data()
        }));
        console.log('x',x);
        setScroll(x[0].isScrollEffect);
        console.log(scroll);
        // console.log(snapshot.docs);
        // setScroll(snapshot.docs.map((doc) => ({
        //   ...doc.data()
        // })));
      });
  };
    getLabels();
    console.log('gotScroll2');
    getScroll();
    setDocHeight(document.documentElement.scrollHeight);
    // scrollDetector();
  }, []);

  useEffect(() => {
    scrollInit();

    // : window.scrollTo(0, document.documentElement.scrollTop);
    if (todos.length > 0) {
      setIsLoaded(true);
      if (!ref.current) {
        downRef.current = false;
        scrollDetector();
        ref.current = true;
      }
    }
  }, [todos, scroll]);

  useEffect(() => {
    setTodosForCsv(
      todos.map((item) => {
        return {
          name: item.todo.name,
          school: item.todo.school,
          description: item.todo.description,
        };
      })
    );
    setDocHeight(document.documentElement.scrollHeight);
  }, [todos]);
  
  const requestFullScreen = (element) => {
    // Supports most browsers and their versions.
    var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
// console.log('active x', window.ActiveXObject)
    if (requestMethod) { // Native full screen.
        requestMethod.call(element);
    } 
    // else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
    //     var wscript = new ActiveXObject("WScript.Shell");
    //     if (wscript !== null) {
    //         wscript.SendKeys("{F11}");
    //     }
    // }
    
}
  const fullscreen = () => {
    requestFullScreen(document.body);
  }

  // console.log("todos", todos);

  return (
    <main className="t3-main-content">
      <FullScreen handle={handle}>
        <header className="t3-header-section">
          <nav className="t3-header-content">
            <Link to={"/"} className="t3-header-logo">
              <img src={logo} alt="logo" draggable="false" />
            </Link>
            <div className="t3-header-right-content">
              {/* {!handle.active && (
                <button onClick={fullscreen} className="t3-header-zoom-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </button>
              )}
              {handle.active && (
                <button onClick={handle.exit} className="t3-header-zoom-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                    />
                  </svg>
                </button>
              )} */}
              <button
                onClick={contentModalHandler}
                className="t3-theme-btn t3-header-zoom-add-content-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Add content</span>
              </button>
              {user?.email && (
                <button onClick={logoutHandler} className="t3-theme-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15M8 8L4 12M4 12L8 16M4 12L16 12"
                    />
                  </svg>
                  <span>Log out</span>
                </button>
              )}
            </div>
          </nav>
        </header>
        <div className="t3-masonry-grid-section">
          <div className="t3-masonry-grid-content" >
            {todos
              ?.sort((a, b) => a.todo.date - b.todo.date)
              .map((todo, i) => (
                <div
                  key={i}
                  className={`t3-masonry-grid-box t3-masonry-grid-box-${
                    color[Math.floor(Math.random() * 9)]
                  }`}
                >
                  <div className="t3-masonry-grid-box-header">
                    <h2 className="t3-masonry-grid-box-header-heading">
                      {todo.todo.name}
                    </h2>
                    {user?.email && (
                      <button
                        onClick={() => deleteHandler(todo.id)}
                        className="t3-masonry-grid-box-close-btn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="t3-masonry-grid-box-body">
                    <p className="t3-masonry-grid-box-sch-name">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                        />
                      </svg>
                      {todo.todo.school}
                    </p>
                    <p
                      className="t3-masonry-grid-box-body-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: todo.todo.description,
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div
            className={`t3-content-modal-bg ${
              contentModal ? "t3-content-modal-open" : ""
            }`}
          ></div>
          <div
            className={`t3-content-modal ${
              contentModal ? "t3-content-modal-open" : ""
            }`}
          >
            <RollingForm setContentModal={setContentModal} />
          </div>
          <div
            className={`t3-content-modal-bg ${
              deleteModal ? "t3-content-modal-open" : ""
            }`}
          ></div>
          <div
            className={`t3-content-modal t3-content-delete-modal ${
              deleteModal ? "t3-content-modal-open" : ""
            }`}
          >
            <DeleteDataModal
              todos={todos}
              deleteModalId={deleteModalId}
              setDeleteModalId={setDeleteModalId}
              setDeleteModal={setDeleteModal}
            />
          </div>
        </div>
        <div className="t3-floting-btn-content">
          {user?.email === "manavjasani@gmail.com" && (
            <>
              <button onClick={deleteAllHandler} className="t3-floting-btn-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </button>
              <button
                onClick={stopScrollHandler}
                className="t3-floting-btn-box"
              >
                {scroll ? "Stop Scroll" : "Start Scroll"}
              </button>
              {todosForCsv.length > 0 && (
                <CSVLink className="t3-floting-btn-box" data={todosForCsv}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  {/* Export CSV */}
                </CSVLink>
              )}
            </>
          )}
        </div>
      </FullScreen>
    </main>
  );
};

export default Todo;
