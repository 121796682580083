import { deleteDoc, doc } from "firebase/firestore";
import React from "react";
import { db } from "../helpers/firebase";
import deleteImg from "../assets/image/DeleteImage.png";

const DeleteDataModal = ({
  todos,
  setDeleteModal,
  deleteModalId,
  setDeleteModalId,
}) => {
  const onCloseHandler = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  const deleteHandler = () => {
    if(todos.length == 0) {
      setDeleteModal(false);
    }
    if (deleteModalId) {
      const todoRef = doc(db, "todos", deleteModalId);
      deleteDoc(todoRef)
        .then(() => {
          console.log("Entire Document has been deleted successfully.");
          setDeleteModal(false);
          setDeleteModalId("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      todos.forEach((item) =>
        deleteDoc(doc(db, "todos", item.id))
          .then(() => {
            console.log("Entire Document has been deleted successfully.");
            setDeleteModal(false);
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
  };

  return (
    <div className="t3-content-modal-content">
      <div className="t3-content-modal-body">
        <img src={deleteImg} alt="delete-img" className="t3-delete-modal-img" />
        <div className="t3-content-modal-header">
          <h2>{`Are you sure you want to delete ${
            deleteModalId ? "this discussion" : "all the discussions"
          }?`}</h2>
        </div>
        <div className="t3-modal-btn-row">
          <button
            type="submit"
            className="t3-theme-outline-btn"
            onClick={onCloseHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="t3-theme-btn"
            onClick={deleteHandler}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDataModal;
