import { Navigate } from "react-router-dom";
import AuthPage from "./helpers/AuthPage";
import { routes } from "./constants";
import LoginForm from "./pages/Login";
import Page404 from "./pages/404";
import Todo from "./pages/Dashboard";

export const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.adminDashboard} />,
    children: [
      { path: routes.login, element: <LoginForm /> },
      { path: routes.todo, element: <Todo /> },
      { path: routes.homepage, element: <Navigate to={routes.todo} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <AuthPage /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.adminDashboard, element: <Todo /> },
      {
        path: routes.homepage,
        element: <Navigate to={routes.adminDashboard} />,
      },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Page404 />,
    children: [
      { path: routes.login, element: <LoginForm /> },
      { path: "*", element: <LoginForm /> },
    ],
  },
];
